import React, { ComponentType, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setUserInfo } from "./users/actions/setUserInfo";
import isEmpty from "lodash/isEmpty";

const Provider: ComponentType = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.users);
  const { push } = useHistory();

  useEffect(() => {
    const userFromLocal = localStorage.getItem("user_byo");

    if (isEmpty(user)) {
      if (userFromLocal) {
        const data = JSON.parse(userFromLocal);
        dispatch(setUserInfo(data));
      } else {
        push("/");
      }
    }
  }, []);

  return <>{children}</>;
};

export default Provider;
