export const colors = {
  primary: "#054368",
  secondary: "#ABCF4B",
  white: "#fff",
  black: "#252733",
  lightGray: "#97ADB6",
  gray: "#F7F8F9",
  grayBorder: "#D5DDE0",
  middleGray: "#A4A6B3",
  danger: "#F02626",
  warning: "#FEC400",
};
