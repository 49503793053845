import React from "react";
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import store from "store/store";
import Login from "pages/Login";
import GlobalStyles from "styles/global/globalStyle";
import { ThemeProvider } from "styled-components";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { theme } from "styles/theme";
import { initializeApp } from "firebaseConfig";
import Panel from "pages/Panel";
import Create from "pages/Create";
import Stands from "pages/Stands";
import Users from "pages/Users";
import Provider from "store/Provider";
import Events from "pages/Events";
import StandUsers from "pages/StandUsers";

const chakraTheme = extendTheme(theme);

function App() {
  initializeApp();

  return (
    <StoreProvider store={store}>
      <ChakraProvider theme={chakraTheme}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Router>
            <Provider>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/panel" component={Panel} />
                <Route exact path="/nuevo" component={Create} />
                <Route exact path="/usuarios" component={Users} />
                <Route exact path="/eventos" component={Events} />
                <Route
                  exact
                  path="/users/stand/:stand_id"
                  component={StandUsers}
                />
                <Route
                  exact
                  path="/stand/:index/event/:event"
                  component={Stands}
                />
              </Switch>
            </Provider>
          </Router>
        </ThemeProvider>
      </ChakraProvider>
    </StoreProvider>
  );
}

export default App;
