import { Dispatch } from "redux";
import { IEvent } from "types/event";
import types from "../types";
import axios from "axios";

export const fetchEvents = () => {
  return (dispatch: Dispatch) => {
    dispatch(fetchEventsStarted());

    axios
      .get(`${process.env.REACT_APP_API_URL}/events/list`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      })
      .then((result: any) => {
        const { data } = result;
        dispatch(fetchEventsSuccess(data));
      })
      .catch((error: Error) => {
        dispatch(fetchEventsFailure(error));
      });
  };
};

export const fetchEventsStarted = () => {
  return {
    type: types.FETCH_EVENT_STARTED,
  };
};

export const fetchEventsSuccess = (events: IEvent[]) => {
  return {
    type: types.FETCH_EVENT_SUCCESS,
    payload: events,
  };
};

export const fetchEventsFailure = (error: Error) => {
  return {
    type: types.FETCH_EVENT_FAILURE,
    payload: { error },
  };
};
