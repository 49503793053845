import firebase from "firebase";
import { Dispatch } from "redux";
import types from "../types";
import voucher from "voucher-code-generator";

export const generateCodes = (qty: number, event: string) => {
  return (dispatch: Dispatch) => {
    dispatch(generateCodesStarted());
    const codes = voucher.generate({ length: 6, count: qty });

    try {
      const db = firebase.firestore();

      for (let i = 0; i < qty; i++) {
        db.collection("codes").doc(codes[i].toUpperCase()).set({
          event,
          status: "VALID",
        });
      }

      dispatch(generateCodesSuccess(codes));
    } catch (error) {
      console.log({ error });
      dispatch(generateCodesFailure(error as Error));
    }
  };
};

export const generateCodesStarted = () => ({
  type: types.GENERATE_CODES_STARTED,
});

export const generateCodesSuccess = (payload: string[]) => ({
  type: types.GENERATE_CODES_SUCCESS,
  payload,
});

export const generateCodesFailure = (error: Error) => ({
  type: types.GENERATE_CODES_FAILURE,
  payload: { error },
});
