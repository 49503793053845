import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ImageIcon from "assets/image.svg";
import Input from "ui/Input";

const StyledDiv = styled.div`
  position: relative;
`;

const StyledSpan = styled.span`
  font-weight: 600;
  position: absolute;
  right: 1.5rem;
  top: 3rem;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

const StyledIcon = styled.img`
  position: absolute;
  left: 1rem;
  top: 2.3rem;
`;

interface Props extends React.HTMLAttributes<HTMLElement> {
  label: string;
  value: any;
  onPictureChange: Function;
}

const FileUpload: React.FunctionComponent<Props> = ({
  label,
  value,
  onPictureChange,
  ...rest
}) => {
  const [picture, setPicture] = useState<FileList | null>();

  const handleUploadPhoto = () => {
    document.getElementById("photo-input")?.click();
  };

  useEffect(() => {
    if (picture && picture[0]) {
      onPictureChange(picture[0]);
    }
  }, [picture]);

  useEffect(() => {
    if (!value || value === {}) {
      setPicture(null);
    }
  }, [value]);

  return (
    <StyledDiv {...rest}>
      {(!picture || !picture[0]) && <StyledIcon src={ImageIcon} />}
      <StyledSpan onClick={() => handleUploadPhoto()}>Subir Foto</StyledSpan>
      <Input
        label={label}
        value={(picture && picture[0] && picture[0].name) || ""}
        onChange={() => {}}
        disabled
      />
      <Input
        inputId="photo-input"
        label={label}
        hidden
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          setPicture(e.currentTarget.files)
        }
        type="file"
      />
    </StyledDiv>
  );
};

export default FileUpload;
