import React, { useEffect, useState, FormEvent } from "react";
import styled from "styled-components";
import Sidebar from "components/Sidebar";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IEvent } from "types/event";
import { Card } from "ui/Card";
import { Button } from "ui/Button";
import Input from "ui/Input";
import { Stand } from "types/stand";
import FileUpload from "ui/FileUpload";
import { loginUserFromStorage } from "store/users/actions/loginUserFromStorage";
import { postEvent } from "store/events/actions/postEvent";
import { uploadStandImage } from "store/events/actions/uploadStandImage";
import { blobToData } from "helpers/blobToData";
import { v4 } from "uuid";

const SmallCard = styled(Card)`
  padding: 2rem;
  width: 40%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  max-height: 50px;
`;

const LargeCard = styled(SmallCard)`
  flex-direction: column;
  max-height: 100%;
  width: 60%;
  align-items: unset;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledH3 = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.middleGray};
`;

const StyledButton = styled(Button)`
  width: 190px;
`;

const InputContainer = styled.div`
  width: 45%;
`;

const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4rem;
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4rem;
`;

const Stands: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  const { event, index } = useParams<{ event: string; index: string }>();
  const [initial, setInitial] = useState(true);
  const [stand, setStand] = useState<Stand>({
    id: "",
    company: "",
    logo: "",
    callToAction: "",
  });
  const { events, loading } = useSelector((state: any) => state.events);
  const { user } = useSelector((state: any) => state.users);
  const [currentEvent, setCurrentEvent] = useState<IEvent | null>(null);
  const { push } = useHistory();

  const updateStand = async () => {
    let updated = { ...currentEvent } as IEvent;

    const result = await blobToData(stand.logo as Blob);
    const logo = await uploadStandImage(result, stand.company);
    updated.stands[parseInt(index)] = {
      ...stand,
      logo,
      id: updated.stands[parseInt(index)]?.id ?? v4(),
    };

    dispatch(postEvent(updated));
  };

  useEffect(() => {
    if (events?.length) {
      const result = events.find((e: IEvent) => e.id == event);
      setCurrentEvent(result);

      if (!initial) {
        push("/panel");
      }
    }
  }, [events]);

  useEffect(() => {
    if (loading) {
      setInitial(false);
    }
  }, [loading]);

  useEffect(() => {
    const data = localStorage.getItem("user_byo");

    if (data) {
      dispatch(loginUserFromStorage(JSON.parse(data)));
    }

    if ((!user || !user.user) && !data) {
      return push("/");
    }
  }, []);

  return (
    <Sidebar title="Nuevo Stand">
      {currentEvent && (
        <SmallCard>
          <Wrapper>
            <StyledH3>{currentEvent.name}</StyledH3>
            <StyledSpan>
              {currentEvent.startDate} - {currentEvent.endDate}
            </StyledSpan>
          </Wrapper>

          <StyledButton
            onClick={() => push("/panel")}
            loading={false}
            color="secondary"
          >
            Editar Evento
          </StyledButton>
        </SmallCard>
      )}
      <LargeCard>
        <StyledH3>Nuevo Stand</StyledH3>

        <Wrap>
          <InputContainer>
            <Input
              value={stand.company}
              label="Empresa"
              onChange={(e: FormEvent<HTMLInputElement>) =>
                setStand({ ...stand, company: e.currentTarget.value })
              }
            />
          </InputContainer>
          <InputContainer>
            <FileUpload
              value={stand.logo}
              label="Logo"
              onPictureChange={(value: any) =>
                setStand({ ...stand, logo: value })
              }
            />
          </InputContainer>
        </Wrap>

        <Input
          value={stand.callToAction}
          label="Call to action"
          placeholder="Ej. Obtené mas descuentos"
          onChange={(e: FormEvent<HTMLInputElement>) =>
            setStand({ ...stand, callToAction: e.currentTarget.value })
          }
        />

        <FlexEnd>
          <StyledButton
            onClick={() => updateStand()}
            color="secondary"
            loading={loading}
          >
            Crear Stand
          </StyledButton>
        </FlexEnd>
      </LargeCard>
    </Sidebar>
  );
};

export default Stands;
