import { css } from "styled-components";

export default css`
  * {
    outline: none;
  }

  body {
    background: ${({ theme }) => theme.colors.background};
    margin: 0;
    font-family: Rokkitt, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  a,
  Link {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: none;

    &:hover {
      font-weight: 500;
    }
  }
`;
