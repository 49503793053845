import axios from "axios";
import { Dispatch } from "redux";
import { IEvent } from "types/event";
import types from "../types";

export const deleteEvent = (event: IEvent) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteEventStarted());

    axios
      .delete(`${process.env.REACT_APP_API_URL}/events/delete/${event.id}`, {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      })
      .then(() => {
        dispatch(deleteEventSuccess(event));
      })
      .catch((error: Error) => {
        dispatch(deleteEventFailure(error));
      });
  };
};

export const deleteEventStarted = () => {
  return {
    type: types.DELETE_EVENT_STARTED,
  };
};

export const deleteEventSuccess = (event: IEvent) => {
  return {
    type: types.DELETE_EVENT_SUCCESS,
    payload: event,
  };
};

export const deleteEventFailure = (error: Error) => {
  return {
    type: types.DELETE_EVENT_FAILURE,
    payload: { error },
  };
};
