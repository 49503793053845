import axios from "axios";
import { Dispatch } from "redux";
import types from "../types";

export const fetchUsers = () => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchUsersStarted());

    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/list`,
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      dispatch(fetchUsersSuccess(result?.data));
    } catch (err) {
      dispatch(fetchUsersFailure(err));
    }
  };
};

export const fetchUsersStarted = () => {
  return {
    type: types.FETCH_USERS_STARTED,
  };
};

export const fetchUsersSuccess = (payload: any) => {
  return {
    type: types.FETCH_USERS_SUCCESS,
    payload,
  };
};

export const fetchUsersFailure = (error: Error) => {
  return {
    type: types.FETCH_USERS_FAILURE,
    payload: { error },
  };
};
