import { AnyAction } from "redux";
import types from "./types";

const initialState = {
  user: {},
  users: [],
  error: null,
  loading: false,
};

export const usersReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.LOGIN_USER_STARTED:
    case types.FETCH_USERS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case types.LOGIN_USER_SUCCESS:
    case types.LOGIN_USER_FROM_STORAGE:
    case types.SET_USER_INFO:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
        error: null,
      };
    case types.LOGIN_USER_FAILURE:
    case types.FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case types.LOGOUT:
      return {
        ...state,
        loading: false,
        user: {},
        error: null,
      };
    default:
      return state;
  }
};
