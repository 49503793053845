import axios from "axios";

export const uploadStandImage = async (image: any, stand: string) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/event/image/upload`,
      {
        image: image,
        name: `event_${stand.toLowerCase()?.replace(/ /g, "_")}`,
      },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );

    return `${process.env.REACT_APP_AWS_S3}/event_${stand
      .toLowerCase()
      ?.replace(/ /g, "_")}`;
  } catch (error) {
    return "";
  }
};
