import firebase from "firebase";
import { Dispatch } from "redux";
import types from "../types";

export const loginUser = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(loginUserStarted());

    const db = firebase.firestore();
    try {
      const user = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      const isAdmin = await db
        .collection("users")
        .where("email", "==", email)
        .get();

      if (isAdmin.docs.length) {
        return dispatch(loginUserSuccess(user));
      }

      throw new Error();
    } catch (e) {
      dispatch(loginUserFailure(e as Error));
    }
  };
};

export const loginUserStarted = () => {
  return {
    type: types.LOGIN_USER_STARTED,
  };
};

export const loginUserSuccess = (data: any) => {
  return {
    type: types.LOGIN_USER_SUCCESS,
    payload: data,
  };
};

export const loginUserFailure = (error: Error) => {
  return {
    type: types.LOGIN_USER_FAILURE,
    payload: { error },
  };
};
