import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Background from "assets/background.png";
import Logo from "assets/logo.svg";
import { Card } from "ui/Card";
import { Button } from "ui/Button";
import Input from "ui/Input";
import { Link } from "ui/Link";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginUser } from "store/users/actions/loginUser";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`;

const StyledCard = styled(Card)`
  max-width: 524px;
  height: 524px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  width: 300px;
`;

const ImageContainer = styled.div`
  width: 100%;
  padding: 1rem 0;
`;

const Copyright = styled.div`
  width: 100%;
  padding: 1rem 0;
  background-color: ${({ theme }) => theme.colors.primary};
  opacity: 0.7;
  color: ${({ theme }) => theme.colors.lightGray};
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

const Form = styled.div`
  width: 100%;
  max-width: 300px;
`;

const StyledLink = styled(Link)`
  font-size: 12px;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const Login: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { loading, error, user } = useSelector((state: any) => state.users);
  const { push } = useHistory();

  useEffect(() => {
    if (user && user.user && !error && !loading) {
      localStorage.setItem("user_byo", JSON.stringify(user));
      push("/panel");
    }
  }, [user]);

  return (
    <Wrapper>
      <ImageContainer>
        <img alt="city background" src={Logo} />
      </ImageContainer>
      <StyledCard>
        <Form>
          <Input
            label="Email"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setEmail(e.currentTarget.value)
            }
          />
          <Input
            type="password"
            label="Password"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setPassword(e.currentTarget.value)
            }
          />
          <StyledLink>OLVIDÉ MI CONTRASEÑA</StyledLink>
        </Form>
        <StyledButton
          color="secondary"
          loading={loading}
          onClick={() => !loading && dispatch(loginUser(email, password))}
        >
          Ingresar
        </StyledButton>
      </StyledCard>
      <Copyright>
        TODOS LOS DERECHOS RESERVADOS / TERMINOS DE USO / POLÍTICAS DE
        PRIVACIDAD
        <br />
        ESTEBAN.VIDELAPEARSON@GMAIL.COM
      </Copyright>
    </Wrapper>
  );
};

export default Login;
