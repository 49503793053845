import { AnyAction } from "redux";
import types from "./types";
import { IEvent } from "types/event";

const initialState = {
  events: [],
  stands: {},
  codes: [],
  loading: false,
  error: null,
};

const eventsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case types.POST_EVENT_STARTED:
    case types.UPDATE_EVENT_STARTED:
    case types.FETCH_EVENT_STARTED:
    case types.DELETE_EVENT_STARTED:
    case types.FETCH_STAND_USERS_STARTED:
    case types.GENERATE_CODES_STARTED:
      return {
        ...state,
        loading: true,
      };
    case types.POST_EVENT_SUCCESS:
    case types.UPDATE_EVENT_SUCCESS:
      let event, events;
      if (typeof action.payload.event.id !== "undefined") {
        events = state.events.map((e: IEvent) =>
          e.id == action.payload.event.id ? action.payload.event : e
        );
      } else {
        event = { ...action.payload.event, id: action.payload.newId };
        events = [...state.events, event];
      }
      return {
        ...state,
        loading: false,
        error: null,
        events,
      };
    case types.FETCH_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        events: action.payload,
      };
    case types.GENERATE_CODES_SUCCESS:
    case types.FETCH_CODES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        codes: action.payload,
      };
    case types.DELETE_EVENT_SUCCESS:
      const deleted = state.events.filter(
        (event: IEvent) => event.id !== action.payload.id
      );

      return {
        ...state,
        loading: false,
        error: null,
        events: deleted,
      };
    case types.FETCH_STAND_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        stands: {
          [action.payload.id]: action.payload,
        },
      };
    case types.POST_EVENT_FAILURE:
    case types.UPDATE_EVENT_FAILURE:
    case types.FETCH_EVENT_FAILURE:
    case types.DELETE_EVENT_FAILURE:
    case types.FETCH_STAND_USERS_FAILURE:
    case types.GENERATE_CODES_FAILURE:
    case types.FETCH_CODES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default eventsReducer;
