import React, { useEffect } from "react";
import styled from "styled-components";
import Sidebar from "components/Sidebar";
import { Card } from "ui/Card";
import { Button } from "ui/Button";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { IEvent } from "types/event";
import Event from "components/Event";
import { fetchEvents } from "store/events/actions/fetchEvent";

const StyledCard = styled(Card)`
  padding: 2rem;
  width: 45%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  box-sizing: border-box;
  max-height: 116px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledH3 = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.middleGray};
`;

const StyledButton = styled(Button)`
  width: 190px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Panel: React.FunctionComponent<{}> = () => {
  const { push } = useHistory();
  const { events } = useSelector((state: any) => state.events);
  const dispatch = useDispatch();

  const renderEvents = () => {
    return events?.map((event: IEvent, i: number) => (
      <Event event={event} index={i} />
    ));
  };

  useEffect(() => {
    if (!events?.length) {
      dispatch(fetchEvents());
    }
  }, []);

  return (
    <Sidebar title="Mi Panel">
      <Container>
        {renderEvents()}
        <StyledCard>
          <Wrapper>
            <StyledH3>EVENTOS</StyledH3>
            <StyledSpan>Es simple y muy rápido</StyledSpan>
          </Wrapper>
          <StyledButton
            onClick={() => push("/nuevo")}
            loading={false}
            color="secondary"
          >
            Crear Evento
          </StyledButton>
        </StyledCard>
      </Container>
    </Sidebar>
  );
};

export default Panel;
