import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
`;

const StyledLabel = styled.label`
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colors.gray};
  border: 0.5px solid ${({ theme }) => theme.colors.grayBorder};
  border-radius: 15px;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  box-sizing: border-box;
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  inputId?: string;
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  ({ label, inputId, hidden, ...rest }, ref) => (
    <Wrapper hidden={hidden}>
      <StyledLabel>{label}</StyledLabel>
      <StyledInput ref={ref} id={inputId} {...rest} />
    </Wrapper>
  )
);

export default Input;
