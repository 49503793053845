import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Sidebar from "components/Sidebar";
import { Card } from "ui/Card";
import Input from "ui/Input";
import { Button } from "ui/Button";
import { IEvent } from "types/event";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FileUpload from "ui/FileUpload";
import { blobToData } from "helpers/blobToData";
import { v4 } from "uuid";
import { postEvent } from "store/events/actions/postEvent";
import { loginUserFromStorage } from "store/users/actions/loginUserFromStorage";
import { usePlacesWidget } from "react-google-autocomplete";

const StyledCard = styled(Card)`
  max-width: 70%;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

const InputContainer = styled.div`
  width: 45%;
`;

const StyledButton = styled(Button)`
  width: 190px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Create: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [initial, setInitial] = useState(true);
  const { events, loading, error } = useSelector((state: any) => state.events);
  const { user } = useSelector((state: any) => state.users);
  const [data, setData] = useState<IEvent>({
    name: "",
    location: "",
    startDate: "",
    endDate: "",
    stands: [],
    url: "",
    image: "",
    range: "",
  });
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      types: "address",
    },
    onPlaceSelected: (place) => {
      setData({
        ...data,
        location: `${place.geometry.location.lat()}, ${place.geometry.location.lng()}`,
      });
    },
  });

  const postData = async () => {
    const result = await blobToData(data.image as Blob);
    dispatch(postEvent({ ...data, image: result as string }));
  };

  const addStands = (value: string) => {
    const result: any[] = [];

    for (let i = 0; i < parseInt(value); i++) {
      result.push({
        id: v4(),
        company: `Stand ${i}`,
        logo: "",
        callToAction: "",
      });
    }

    return result;
  };

  useEffect(() => {
    if (events?.length && !loading && !error) {
      setData({
        name: "",
        location: "",
        startDate: "",
        endDate: "",
        stands: [],
        url: "",
        image: "",
        range: "",
      });

      if (!initial) {
        push("/panel");
      }
    }
  }, [events]);

  useEffect(() => {
    if (loading) {
      setInitial(false);
    }
  }, [loading]);

  useEffect(() => {
    const data = localStorage.getItem("user_byo");

    if (data) {
      dispatch(loginUserFromStorage(JSON.parse(data)));
    }

    if ((!user || !user.user) && !data) {
      return push("/");
    }
  }, []);

  return (
    <Sidebar title="Eventos">
      <StyledCard>
        <h3>NUEVO EVENTO</h3>
        <Wrapper>
          <InputContainer>
            <Input
              value={data.name}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setData({ ...data, name: e.currentTarget.value })
              }
              label="Nombre"
            />
          </InputContainer>
          <InputContainer>
            <Input
              ref={ref as any}
              value={data.location}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setData({ ...data, location: e.currentTarget.value })
              }
              label="Ubicación"
            />
          </InputContainer>
          <InputContainer>
            <Input
              value={data.startDate}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setData({ ...data, startDate: e.currentTarget.value })
              }
              label="Fecha de inicio"
              type="date"
            />
          </InputContainer>
          <InputContainer>
            <Input
              value={data.endDate}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setData({ ...data, endDate: e.currentTarget.value })
              }
              label="Fecha de fin"
              type="date"
            />
          </InputContainer>
          <InputContainer>
            <Input
              value={data.stands?.length}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setData({ ...data, stands: addStands(e.currentTarget.value) })
              }
              label="Stands"
              type="number"
            />
          </InputContainer>
          <InputContainer>
            <Input
              value={data.url}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setData({ ...data, url: e.currentTarget.value })
              }
              label="Página web"
            />
          </InputContainer>
          <InputContainer>
            <FileUpload
              value={data.image}
              onPictureChange={(value: any) =>
                setData({ ...data, image: value })
              }
              label="Cargar foto de perfil"
            />
          </InputContainer>
          <InputContainer>
            <Input
              value={data.range}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setData({ ...data, range: e.currentTarget.value })
              }
              label="Delimitar rango de alcance en KM"
            />
          </InputContainer>
        </Wrapper>

        <Flex>
          <StyledButton
            onClick={() => !loading && postData()}
            color="secondary"
            loading={loading}
          >
            Crear Evento
          </StyledButton>
        </Flex>
      </StyledCard>
    </Sidebar>
  );
};

export default Create;
