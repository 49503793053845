import React, { useState } from "react";
import styled from "styled-components";
import { Card } from "ui/Card";
import { Button } from "ui/Button";
import { IEvent } from "types/event";
import { Stand } from "types/stand";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { postEvent } from "store/events/actions/postEvent";
import { deleteEvent } from "store/events/actions/deleteEvent";
import Input from "ui/Input";
import isEmpty from "lodash/isEmpty";
import { generateCodes } from "store/events/actions/generateCodes";
import { fetchEventCodes } from "store/events/actions/fetchEventCodes";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const StyledCard = styled(Card)`
  padding: 2rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  box-sizing: border-box;
`;

const CardContainer = styled(StyledCard)`
  max-height: 116px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StandsWrapper = styled(Wrapper)`
  width: 100%;
`;

const StyledH3 = styled.h3`
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.middleGray};
`;

const StyledButton = styled(Button)`
  width: 190px;
`;

const List = styled.ul`
  width: 100%;
  list-style: none;
  padding-left: 0;
  padding-top: 1rem;

  li {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grayBorder};

    :last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
`;

const Item = styled.li`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
`;

const SmallButton = styled.button<{ color: string }>`
  display: flex;
  align-items: center;
  background-color: ${({ color, theme }) => theme.colors[color]};
  font-size: 10px;
  border: none;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.white};
  padding: 5px;
  margin: 0 2px;
  max-height: 20px;
`;

const StandActions = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const StandDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const CTA = styled.span`
  font-style: italic;
  font-size: 14px;
  margin-top: 0.5rem;
`;

const DeleteButton = styled.button`
  border: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.danger};
  font-size: 1rem;
  padding: 1rem;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 16px;
`;

interface Props {
  event: IEvent;
  index: number;
}

const Event: React.FunctionComponent<Props> = ({ event, index }) => {
  const [open, setOpen] = useState(false);
  const [codesOpen, setCodesOpen] = useState(false);
  const [codes, setCodes] = useState(0);
  const [data, setData] = useState({
    startDate: event.startDate,
    endDate: event.endDate,
  });
  const { loading, codes: eventCodes } = useSelector(
    (state: any) => state.events
  );
  const dispatch = useDispatch();

  const removeStand = (index: number) => {
    const removed = event;
    removed.stands[index] = {
      logo: "",
      company: `Stand ${index}`,
      callToAction: "",
    };

    dispatch(postEvent(removed));
  };

  const openCodeModal = async () => {
    await dispatch(fetchEventCodes(event.id ?? ""));
    setCodesOpen(true);
  };

  const updateEvent = () => {
    if (!isEmpty(data)) {
      const updated = {
        ...event,
        ...data,
      };

      if (codes) {
        dispatch(generateCodes(codes, event.id ?? ""));
      }

      dispatch(postEvent(updated));
    }
  };

  const removeEvent = () => {
    dispatch(deleteEvent(event));
  };

  const renderStands = () => {
    return event.stands.map((stand: Stand, i: number) => (
      <Item key={i}>
        <StandDescription>
          <span>{stand.company}</span>
          <CTA>{stand.callToAction}</CTA>
        </StandDescription>
        {stand.company === `Stand ${i}` ? (
          <Link to={`/stand/${i}/event/${event.id}`}>+ Crear stand</Link>
        ) : (
          <StandActions>
            {/* <SmallButton color="warning">EDITAR</SmallButton> */}
            <SmallButton onClick={() => removeStand(i)} color="danger">
              ELIMINAR
            </SmallButton>
          </StandActions>
        )}
      </Item>
    ));
  };

  return (
    <Flex>
      <CardContainer key={index}>
        <Wrapper>
          <StyledH3>{event.name}</StyledH3>
          <StyledSpan>
            {event.startDate} - {event.endDate}
          </StyledSpan>
        </Wrapper>
        <StyledButton
          onClick={() => setOpen(!open)}
          loading={false}
          color={!open ? "secondary" : "danger"}
        >
          {!open ? "Editar Evento" : "Cancelar"}
        </StyledButton>
        {!open && (
          <DeleteButton onClick={() => removeEvent()}>Eliminar</DeleteButton>
        )}
      </CardContainer>
      {open && (
        <StyledCard>
          <StandsWrapper>
            <StyledH3>Stands</StyledH3>
            <span>{event.name}</span>
            <Input
              value={data?.startDate}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setData({ ...data, startDate: e.currentTarget.value })
              }
              label="Fecha de inicio"
              type="date"
            />
            <Input
              value={data?.endDate}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setData({ ...data, endDate: e.currentTarget.value })
              }
              label="Fecha de fin"
              type="date"
            />
            <List>{event?.stands && renderStands()}</List>
            <Input
              defaultValue={codes}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setCodes(Number(e.currentTarget.value))
              }
              label="Generar códigos"
              type="number"
            />
            <ButtonContainer>
              <Button loading={loading} color="primary" onClick={updateEvent}>
                Actualizar
              </Button>
              <Button loading={false} color="secondary" onClick={openCodeModal}>
                Ver Códigos
              </Button>
            </ButtonContainer>
          </StandsWrapper>
        </StyledCard>
      )}

      <Modal isOpen={codesOpen} onClose={() => setCodesOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Listado de Códigos</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Th>Código</Th>
              </Thead>
              <Tbody>
                {eventCodes?.map((code: string) => (
                  <Tr key={code}>
                    <Td>{code}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Event;
