import React from "react";
import styled from "styled-components";
import LogoSmall from "assets/logo_small.svg";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { logout } from "store/users/actions/logout";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
`;

const StyledSidebar = styled.div`
  position: fixed;
  width: 20%;
  height: 100%;
  padding: 2rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const Content = styled.div`
  padding: 1rem;
  margin-left: 20%;
  min-height: 100vh;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray};
`;

const SidebarTitle = styled.h3`
  color: ${({ theme }) => theme.colors.middleGray};
  font-weight: bold;
  font-size: 18px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  margin-bottom: 4rem;
`;

const Logout = styled.span`
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-weight: 400;
  position: absolute;
  bottom: 4rem;
  margin: auto;
`;

const Nav = styled.div`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.secondary};
`;

interface Props {
  children: React.ReactNode;
  title: string;
}

const Sidebar: React.FunctionComponent<Props> = ({ children, title }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const logoutUser = () => {
    localStorage.removeItem("user_byo");
    dispatch(logout());
    push("/");
  };

  return (
    <Wrapper>
      <StyledSidebar>
        <Header>
          <img src={LogoSmall} />
          <SidebarTitle>Busco y Ofrezco</SidebarTitle>
        </Header>
        <Nav>
          <Link to="/panel">Mi Panel</Link>
        </Nav>
        <Nav>
          <Link to="/usuarios">Usuarios</Link>
        </Nav>
        <Nav>
          <Link to="/eventos">Eventos</Link>
        </Nav>
        <Logout onClick={() => logoutUser()}>Cerrar Sesión</Logout>
      </StyledSidebar>
      <Content>
        <Title>{title}</Title>
        {children}
      </Content>
    </Wrapper>
  );
};

export default Sidebar;
