import axios from "axios";
import { Dispatch } from "redux";
import { IEvent } from "types/event";
import types from "../types";

export const postEvent = (event: IEvent) => {
  return (dispatch: Dispatch) => {
    dispatch(postEventStarted());

    axios.post(
      `${process.env.REACT_APP_API_URL}/event/image/upload`,
      {
        image: event.image,
        name: `event_${event.name.toLowerCase()?.replace(/ /g, "_")}`,
      },
      {
        headers: {
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
      }
    );

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/events/create`,
        {
          ...event,
          image: `${process.env.REACT_APP_AWS_S3}/event_${event.name
            .toLowerCase()
            ?.replace(/ /g, "_")}`,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
      .then((result: any) => {
        const { data } = result;
        dispatch(postEventSuccess(event, data.id));
      })
      .catch((error: Error) => {
        dispatch(postEventFailure(error));
      });
  };
};

export const postEventStarted = () => {
  return {
    type: types.POST_EVENT_STARTED,
  };
};

export const postEventSuccess = (event: IEvent, newId: string) => {
  return {
    type: types.POST_EVENT_SUCCESS,
    payload: { event, newId },
  };
};

export const postEventFailure = (error: Error) => {
  return {
    type: types.POST_EVENT_FAILURE,
    payload: { error },
  };
};
