import firebase from "firebase";
import { Dispatch } from "redux";
import types from "../types";

export const fetchEventCodes = (event: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(fetchEventCodesStarted());

    try {
      const db = firebase.firestore();
      const data = await db
        .collection("codes")
        .where("event", "==", event)
        .get();

      const codes = await Promise.all(data.docs.map((doc) => doc.id));

      dispatch(fetchEventCodesSuccess(codes));
    } catch (error) {
      console.log({ error });
      dispatch(fetchEventCodesFailure(error as Error));
    }
  };
};

export const fetchEventCodesStarted = () => ({
  type: types.FETCH_CODES_STARTED,
});

export const fetchEventCodesSuccess = (payload: string[]) => ({
  type: types.FETCH_CODES_SUCCESS,
  payload,
});

export const fetchEventCodesFailure = (error: Error) => ({
  type: types.FETCH_CODES_FAILURE,
  payload: { error },
});
