import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import Sidebar from "components/Sidebar";
import React from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { fetchStandUsers } from "store/events/actions/fetchStandUsers";
import { fetchUsers } from "store/users/actions/fetchUsers";
import styled from "styled-components";

const Export = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
`;

const StandUsers: React.FC = () => {
  const dispatch = useDispatch();
  const { stand_id } = useParams<{ stand_id: string }>();
  const standUsers = useSelector(
    (state: any) => state.events.stands?.[stand_id]?.users
  );
  const { users } = useSelector((state: any) => state.users);
  const [tableUsers, setTableUsers] = React.useState([]);
  const [csvReport, setCSVReport] = React.useState<any>({ data: "" });

  React.useEffect(() => {
    if (!users?.length) {
      dispatch(fetchUsers());
    }
  }, []);

  React.useEffect(() => {
    if (!standUsers?.length && stand_id) {
      dispatch(fetchStandUsers(stand_id));
    }
  }, [standUsers]);

  React.useEffect(() => {
    if (standUsers?.length && users?.length) {
      const result = standUsers.map((standUser: any) =>
        users.find((user: any) => standUser.user_id === user.id)
      );

      setTableUsers(result);
      setCSVReport({
        data: result?.map((user: any) => ({
          id: user?.id,
          nombre: user?.displayName,
          email: user?.email,
        })),
      });
    }
  }, [standUsers, users]);
  return (
    <Sidebar title="Usuarios por stand">
      <Table variant="simple">
        <Thead>
          <Th>ID</Th>
          <Th>Nombre</Th>
          <Th>Email</Th>
        </Thead>
        <Tbody>
          {tableUsers?.map(
            (user: any) =>
              user && (
                <Tr key={user.id}>
                  <Td>{user.id}</Td>
                  <Td>{user.displayName}</Td>
                  <Td>{user.email}</Td>
                </Tr>
              )
          )}
        </Tbody>
      </Table>
      <Export>
        <CSVLink data={csvReport.data} filename={`reporte_stand_${stand_id}`}>
          Exportar
        </CSVLink>
      </Export>
    </Sidebar>
  );
};

export default StandUsers;
