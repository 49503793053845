import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import Sidebar from "components/Sidebar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "store/events/actions/fetchEvent";
import { fetchStandUsers } from "store/events/actions/fetchStandUsers";
import styled from "styled-components";
import { IEvent } from "types/event";
import { Stand } from "types/stand";
import { Button } from "ui/Button";
import { useHistory } from "react-router";

const ViewButton = styled(Button)`
  width: 100%;
`;

const LinkButton = styled(Button)`
  width: 100%;
  padding: 8px;
  margin: 8px auto;
`;

const Events: React.FC = () => {
  const dispatch = useDispatch();
  const { events } = useSelector((state: any) => state.events);
  const [isOpen, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<IEvent | null>(null);
  const history = useHistory();

  const handleStandExport = async (id = "") => {
    if (id) {
      await dispatch(fetchStandUsers(id));
      history.push(`/users/stand/${id}`);
    }
  };

  const handleViewClick = (event: IEvent) => {
    setSelected(event);
    setOpen(true);
  };

  React.useEffect(() => {
    if (!events.length) {
      dispatch(fetchEvents());
    }
  }, []);

  return (
    <Sidebar title="Eventos">
      <Table variant="simple">
        <Thead>
          <Th>Nombre</Th>
          <Th>Inicio</Th>
          <Th>Fin</Th>
          <Th />
        </Thead>
        <Tbody>
          {events?.map((event: IEvent) => (
            <Tr key={event.id}>
              <Td>{event.name}</Td>
              <Td>{event.startDate}</Td>
              <Td>{event.endDate}</Td>
              <Td>
                <ViewButton
                  onClick={() => handleViewClick(event)}
                  loading={false}
                  color="primary"
                >
                  Ver
                </ViewButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selected?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Table variant="simple">
              <Thead>
                <Th>Empresa</Th>
                <Th>Frase gancho</Th>
                <Th>Usuarios</Th>
              </Thead>
              <Tbody>
                {selected?.stands?.map((stand: Stand) => (
                  <Tr key={stand.id}>
                    <Td>{stand.company}</Td>
                    <Td>{stand.callToAction}</Td>
                    <LinkButton
                      color="primary"
                      loading={false}
                      onClick={() => handleStandExport(stand.id)}
                    >
                      Ver
                    </LinkButton>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Sidebar>
  );
};

export default Events;
