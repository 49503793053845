import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import Sidebar from "components/Sidebar";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "store/users/actions/fetchUsers";

const Users: React.FC = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state: any) => state.users);

  useEffect(() => {
    if (!users?.length) {
      dispatch(fetchUsers());
    }
  }, []);

  return (
    <Sidebar title="Usuarios">
      <Table variant="simple">
        <Thead>
          <Th>Nombre</Th>
          <Th>Email</Th>
          <Th>Ofrece</Th>
          <Th>Busca</Th>
        </Thead>
        <Tbody>
          {users.map((user: any) => (
            <Fragment key={user.id}>
              <Tr>
                <Td>{user.displayName}</Td>
                <Td>{user.email}</Td>
                <Td>{user.offer?.title}</Td>
                <Td>{user.search?.title}</Td>
              </Tr>
            </Fragment>
          ))}
        </Tbody>
      </Table>
    </Sidebar>
  );
};

export default Users;
