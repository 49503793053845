const types = {
  SET_USER_INFO: "SET_USER_INFO",
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_FROM_STORAGE: "LOGIN_USER_FROM_STORAGE",
  LOGIN_USER_STARTED: "LOGIN_USER_STARTED",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  FETCH_USERS_STARTED: "FETCH_USERS_STARTED",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_FAILURE: "FETCH_USERS_FAILURE",
  LOGOUT: "LOGOUT",
};

export default types;
