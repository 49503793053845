const types = {
  POST_EVENT: "POST_EVENT",
  POST_EVENT_STARTED: "POST_EVENT_STARTED",
  POST_EVENT_SUCCESS: "POST_EVENT_SUCCESS",
  POST_EVENT_FAILURE: "POST_EVENT_FAILURE",
  FETCH_EVENT: "FETCH_EVENT",
  FETCH_EVENT_STARTED: "FETCH_EVENT_STARTED",
  FETCH_EVENT_SUCCESS: "FETCH_EVENT_SUCCESS",
  FETCH_EVENT_FAILURE: "FETCH_EVENT_FAILURE",
  DELETE_EVENT: "DELETE_EVENT",
  DELETE_EVENT_STARTED: "DELETE_EVENT_STARTED",
  DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
  DELETE_EVENT_FAILURE: "DELETE_EVENT_FAILURE",
  UPDATE_EVENT_STARTED: "UPADTE_EVENT_STARTED",
  UPDATE_EVENT_SUCCESS: "UPADTE_EVENT_SUCCESS",
  UPDATE_EVENT_FAILURE: "UPADTE_EVENT_FAILURE",
  FETCH_STAND_USERS_STARTED: "FETCH_STAND_USERS_STARTED",
  FETCH_STAND_USERS_SUCCESS: "FETCH_STAND_USERS_SUCCESS",
  FETCH_STAND_USERS_FAILURE: "FETCH_STAND_USERS_FAILURE",
  GENERATE_CODES_STARTED: "GENERATE_CODES_STARTED",
  GENERATE_CODES_SUCCESS: "GENERATE_CODES_SUCCESS",
  GENERATE_CODES_FAILURE: "GENERATE_CODES_FAILURE",
  FETCH_CODES_STARTED: "FETCH_CODES_STARTED",
  FETCH_CODES_SUCCESS: "FETCH_CODES_SUCCESS",
  FETCH_CODES_FAILURE: "FETCH_CODES_FAILURE",
};

export default types;
