import React from "react";
import styled, { css } from "styled-components";
import Spinner from "ui/Spinner";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  loading: boolean;
  color: string;
  children: React.ReactNode;
}

const StyledButton = styled.button<{ color: string; loading: boolean }>`
  border-radius: 60px;
  padding: 1rem;
  background-color: ${({ color, theme }) => theme.colors[color]};
  border: 0;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({ loading }) =>
    loading &&
    css`
      padding: 0;
      width: 50px;
    `}
`;

export const Button: React.FunctionComponent<Props> = ({
  loading,
  color,
  children,
  ...rest
}) => (
  <StyledButton loading={loading} color={color} {...rest}>
    {loading ? <Spinner /> : children}
  </StyledButton>
);
